<template>
  <div class="style-bank">
    <div class="style-list">
      <div class="header flex a-center j-between">
          <div class="title">样式列表</div>
          <div class="ops">
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-plus"
              @click="showAddDialog"
            >新增</el-button>
            <el-button
              v-if="false"
              type="success"
              size="mini"
              @click="importVisible = true"
              >
              导入
            </el-button>
          </div>
      </div>
      <div class="body">
        <el-collapse v-model="actCollapse">
          <el-collapse-item name="title">
            <template slot="title">
              标题（{{styleBank['title'].length}}）
            </template>
            <div class="item-container">
              <div
                @click="clickItem(item)"
                :class="{'act-item': actClass === item}"
                class="list-item"
                v-for="(item, index) in styleBank['title']"
                :key="index">
              {{item.style_name}}
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="style-content" style="height: auto">
      <div class="header flex a-center j-between">
          <div class="title">样式配置</div>
          <div class="lib" v-show="config" :class="{'close-icon': closed}" @click="closed=!closed">版式库<i class="el-icon-caret-top"></i></div>
      </div>

      <div class="body style-config" v-if="config">
        <div class="style-lib" :class="{ closed }">
          <div class="condition-box">
            <div class="left">
            </div>
            <div class="right">
              <i class="el-icon-search"></i>
              <input type="text" placeholder="输入关键字" v-model="condition.search">
            </div>
          </div>
          <el-tabs class="style-lib-tabs" v-model="condition.formatModel">
            <el-tab-pane v-for="(item, index) in formatList" :key="item.label + index" :name="item.value">
              <span slot="label" class="tab-item">
                <img :src="item.img" alt="" class="tab-item-img">
                <span class="tab-item-text">{{item.label}}</span>
              </span>
              <div class="style-box">
                <div class="item" v-for="(child, i) in formatDataList" :key="i + child.data.name" :class="{'act-item': actFormat.data.name===child.data.name}">
                  <i class="el-icon-error del" @click.stop="delComponent(child)"></i>
                  <el-image :src="child.data.img" alt="" class="img" fit="cover" />
                  <div class="text-box">
                    <i class="el-icon-success" v-if="actFormat.data.name===child.data.name"></i>
                    <span>{{child.data.name}}</span>
                  </div>
                  <div class="button-box">
                    <el-button size="mini" class="button" @click="selectFormat(child)">套用</el-button>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <el-checkbox-group :value="config.fbd.enable_fbd" @input="config.fbd.enable_fbd = !config.fbd.enable_fbd">
          <el-checkbox label="是否使用方正代码进行配置"></el-checkbox>
        </el-checkbox-group>
        <div class="preview" v-if="false">
          <span class="btn">
            <el-popover
              placement="bottom"
              width="400"
              trigger="hover">
              <div>
                <span>透明度</span>
                <el-slider v-model="compareStyle.opacity" :step="0.1" show-stops :max="1" size="mini" />
              </div>
              <div>
                <el-button @click="$refs.compareFile.click()" type="primary" size="mini">{{ comparePic.url ? '更换比对图' : '添加对比图' }}</el-button>
                <el-button @click="removeComparePic" type="primary" v-if="comparePic.url" size="mini">删除对比图</el-button>
              </div>
              <input ref="compareFile" type="file" accept="image/*" class="file-input" @change="addComparePic" />
              <el-button size="mini" style="padding: 0" class="style-tool-button--text" slot="reference"  icon="el-icon-plus">对比图</el-button>
            </el-popover>
            <el-button class="style-tool-button--text m-l-10" @click="preview" :loading="previewLoading">预览</el-button>
          </span>

          <div class="compare-container" v-if="comparePic.url" :style="{ opacity: compareStyle.opacity }"><img :src="comparePic.url" alt="" class="compare-pic"></div>
          <ChapterPreview :styleProp="styleData" :jsonProp="[jsonProp]" ref="ChapterPreview" class="chapter-preview" />
        </div>
        <div class="no-preview" v-else>
          暂无预览
        </div>
        <div class="config">
          <el-button class="style-tool-button--text right-btn" @click.stop="setComponent" icon="el-icon-plus">生成版式</el-button>
          <el-tabs v-model="actComponent">
            <el-tab-pane label="整体配置" name="_self">
              <el-form :inline="true" size="mini" label-width="200px">
                <el-form-item label="标题显示：">
                  <el-radio-group v-model="config.display._self">
                    <el-radio :label="true">显示标题内容</el-radio>
                    <el-radio :label="false">不显示标题内容</el-radio>
                  </el-radio-group>
                </el-form-item>
                <br>
                <el-form-item label="是否根据开头数字增加字控：">
                  <el-checkbox v-model="config.config._self.is_chapter_num_zk"></el-checkbox>
                </el-form-item>
                <br>
                <el-form-item label="标题另起：">
                  <el-select v-model="config.config._self.page_break" :placeholder="placeholder.config._self.page_break" :popper-append-to-body="false" class="style-tool-select" clearable>
                    <el-option v-for="(value, key) in pageBreaks" :key="key" :label="key" :value="value" />
                  </el-select>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="文字样式" name="font_style" lazy>
              <BaseStyle :styleData="styleData" :extra_font_list="styleData.extra_font_list" v-model="config.style._self" :placeholder="placeholder.style._self">
                <template>
                  <el-form label-width="70px">
                    <el-form-item label="文本装饰：">
                      <el-select v-model="config.style._self.text_decoration" :placeholder="placeholder.style._self.text_decoration || '无'" :popper-append-to-body="false" class="style-tool-select" clearable>
                        <el-option label="下划线" value="underline" />
                        <el-option label="删除线" value="strike" />
                        <el-option label="双下划线" value="emphasis_double" />
                        <el-option label="波浪线" value="emphasis_wave" />
                        <el-option label="着重号" value="emphasis_dot" />
                      </el-select>
                    </el-form-item>
                  </el-form>
                  <el-form label-width="70px">
                    <el-form-item label="加粗：">
                      <el-select v-model="config.style._self.text_bold" :placeholder="placeholder.style._self.text_bold || '正常'" :popper-append-to-body="false" class="style-tool-select" clearable>
                        <el-option label="粗1" :value="1" />
                        <el-option label="粗2" :value="2" />
                        <el-option label="粗3" :value="3" />
                        <el-option label="粗4" :value="4" />
                      </el-select>
                    </el-form-item>
                  </el-form>
                  </template>
              </BaseStyle>
              <el-form :inline="true" size="mini" label-width="70px">
                <BaseStyle class="flex-1" :styleData="styleData" :extra_font_list="styleData.extra_font_list" v-model="config.style._self.before" :exclude="['text_align', 'line_spacing']">
                  <template slot="before">
                    <el-form-item label="前插入：">
                      <el-input v-model="config.style._self.before.content" :placeholder="placeholder.style._self.before.content" class="style-tool-input" />
                    </el-form-item>
                  </template>
                </BaseStyle>
                <BaseStyle :styleData="styleData" class="flex-1" :extra_font_list="styleData.extra_font_list" v-model="config.style._self.after" :exclude="['text_align', 'line_spacing']">
                  <template slot="before">
                    <el-form-item label="后插入：">
                      <el-input v-model="config.style._self.after.content" :placeholder="placeholder.style._self.after.content" class="style-tool-input" />
                    </el-form-item>
                  </template>
                </BaseStyle>
                <el-form-item label="清除HTML样式：" label-width="120px" class="clear-html-style" v-if="false">
                  <el-checkbox-group v-model="config.html_style_clean._self">
                    <el-checkbox :label="item.value" v-for="item in htmlStyleCleanList" :key="item.value">{{item.label}}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="装饰图文" name="decorations" lazy>
              <Decorations :decorations="config.config._self.decorations" :styleData="styleData" />
            </el-tab-pane>
            <el-tab-pane label="分栏配置" name="column" lazy>
              <ColumnConfig  :value="config.config._self" :placeholder="placeholder.config._self" :styleData="styleData" />
            </el-tab-pane>
            <el-tab-pane label="标题位置" name="chapter_position" lazy>
              <el-form :inline="true" size="mini" label-width="70px">
                <el-form-item label="标题左移：">
                  <format-input type="number" v-model="config.config._self.margin.left" :placeholder="placeholder.config._self.margin.left" />
                </el-form-item>
                <el-form-item label="标题右移：">
                  <format-input type="number" v-model="config.config._self.margin.right" :placeholder="placeholder.config._self.margin.right" />
                </el-form-item>
                <br>
                <el-form-item label="前节间距：">
                  <format-input type="number" v-model="config.config._self.margin.top" :placeholder="placeholder.config._self.margin.top" />
                </el-form-item>
                <el-form-item label="后节间距：">
                  <format-input type="number" v-model="config.config._self.margin.bottom" :placeholder="placeholder.config._self.margin.bottom" />
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="关键词" name="_keyword" lazy>
              <HtmlKeyWord v-model="config.html._self._keyword" :styleData="styleData" :extra_font_list="styleData.extra_font_list" />
            </el-tab-pane>
          </el-tabs>
        </div>
        <div v-if="config.fbd.enable_fbd" class="fbd-lable">方正代码配置：</div>
              <FbdEditor
                v-if="config.fbd.enable_fbd"
                v-model="config.fbd.code_template"
                :options="{ height: 150 }"
              />
      </div>
    </div>
    <el-dialog
      title="新增样式"
      :visible.sync="addVisible"
      width="400px"
      @close="addMode = 'title'; configName = ''"
      :close-on-click-modal="false"
    >
      <div>
        <div class="m-b-15">
          <el-input size="mini" v-model.trim="configName" placeholder="请输入样式名"></el-input>
        </div>
        <div>
          <el-radio v-model="addMode" label="title">标题</el-radio>
          <el-radio v-model="addMode" disabled label="question">试题</el-radio>
          <el-radio v-model="addMode" disabled label="paragraph">段落</el-radio>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="addVisible = false" size="small">取 消</el-button>
        <el-button type="primary" :disabled="!configName.trim()" @click="addConfig" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="导入样式"
      :visible.sync="importVisible"
      width="500px"
      :close-on-click-modal="false"
      >
      <div>
        <div>
          <span>所属客户</span>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="importVisible = false">取 消</el-button>
        <el-button type="primary" @click="importVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { defaultQuestion, defaultChapter, defaultParagraph } from '@/scripts/styleToolDefault';
import { catalog } from '../assets/componentCatalog.js';
// import _ from 'lodash'
import { mergeDefault } from '@/scripts/helper';
import { pageBreaks, htmlStyleCleanList } from '@/assets/styleTool/map';
import FormatInput from '../components/FormatInput';
import BaseStyle from '../components/BaseStyle';
import ColumnConfig from '../components/ColumnConfig';
import ChapterPreview from '../components/ChapterPreview';
import Decorations from '../components/Decorations';
import HtmlKeyWord from '../components/HtmlKeyWord';

import editor from 'hexin-ckeditor-doc-vue/dist/editor.umd.js';
import 'hexin-ckeditor-doc-vue/dist/editor.css';
const FbdEditor = editor.FbdEditor;

export default {
  inheritAttrs: false,
  props: {
    styleData: {
      required: true,
      type: Object,
    }
  },
  components: {
    FormatInput,
    BaseStyle,
    ColumnConfig,
    ChapterPreview,
    Decorations,
    HtmlKeyWord,
    FbdEditor
  },
  mounted () {
    this.clickItem(this.styleData.style_bank.title[0]);
  },
  data () {
    return {
      addVisible: false,
      importVisible: false,
      configName: '',
      addMode: 'title',
      actClass: null,
      actCollapse: ['title'],
      // 样式配置相关
      condition: {
        formatModel: '',
        search: ''
      },
      jsonProp: {
        closed: false,
        content: Object,
        isLeaf: true,
        node_id: 'e4c05d',
        node_level: 3,
        node_name: '模拟标题',
        node_parent_id: '02d8b8',
        node_type: 'chapter',
        order: 1
      },
      actComponent: '_self',
      actFormat: {
        data: {}
      },
      formatData: [],
      closed: false,
      selectIndex: '',
      config: null,
      showNopreview: false,
      placeholder: null,
      htmlStyleCleanList: htmlStyleCleanList,
      pageBreaks,
    };
  },
  computed: {
    formatList () {
      const formatList = catalog.module.chapter.model;
      if (!formatList) return [];
      return formatList;
    },
    styleBank () {
      return this.styleData.style_bank;
    },
    formatDataList () {
      const { search } = this.condition;
      return this.formatData.filter(item => item.data.name.indexOf(search) >= 0);
    },
  },
  methods: {
    showAddDialog () {
      this.addVisible = true;
    },
    addConfig () {
      const { addMode, configName } = this;
      let style_object;
      switch (addMode) {
      case 'title': style_object = defaultChapter({ identity: { content: { level: '' }, _parent: {} } });
        break;
      case 'question': style_object = defaultQuestion({ identity: { content: { level: '' }, _parent: {} } });
        break;
      case 'paragraph': style_object = defaultParagraph({ identity: { content: { level: '' }, _parent: {} } });
        break;
      }
      const obj = {
        style_name: configName,
        style_object
      };
      this.styleData.style_bank[addMode].push(obj);
      this.actClass = obj;
      this.addVisible = false;
    },
    clickItem (item) {
      this.actClass = item;
      this.actClassChange(item.style_object);
    },
    actClassChange (c) {
      if (!c) return;
      this.showNopreview = false;
      this.config = c;
      this.actFormat = { data: {} };
      // const node = this.chapterList.find(i => i.content.level === c.identity.content.level)
      // const node = null;
      // const index = this.templateChapterClass.findIndex(i => {
      //   const t = _.cloneDeep(c)
      //   const n = _.cloneDeep(i)
      //   delete t.identity && delete n.identity
      //   return _.isEqual(t, n)
      // })
      // this.selectIndex = (index === -1 ? '' : index)
      const { default_style } = this.styleData.components[0];
      this.placeholder = mergeDefault(defaultChapter(), default_style);

      // if (node) {
      //   delete node.children
      //   this.jsonProp = _.cloneDeep(node)
      // } else {
      //   this.showNopreview = true
      // }
    },
  }
};
</script>

<style lang="scss" scoped>
$main-color: #409EFF;
$danger-color: #F56C6C;
.style-bank {
  display: flex;
  padding: 15px;

  .style-list {
    // width: 20%;
    min-width: 300px;
    max-width: 300px;
    margin-right: 25px;

    .item-container {

      .list-item {
        padding: 0 10px;
        height: 30px;
        line-height: 30px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        border-bottom: 1px solid #cecece;
        cursor: pointer;
        &:hover {
          background: rgba(72, 99, 143, 0.08);
        }
      }

      .act-item {
        background: rgba(72, 99, 143, 0.08);
      }
    }
  }

  .style-content {
    flex-grow: 1;
    min-width: 500px;

    .style-config {
      height: calc(100% - 42px);
      display: flex;
      flex-direction: column;
      .style-lib {
        flex: none;
        position: relative;
        max-height: 400px;
        // border: 1px solid #ccc;
        margin-top: 4px;
        .style-lib-tabs {
          padding: 0 20px;
          /deep/ .el-tabs__header {
            margin-bottom: unset;
          }
          /deep/ .el-tabs__item {
            height: 50px;
          }
          .tab-item {
            display: flex;
            flex-direction: column;
            .tab-item-img {
              flex: none;
              height: 30px;
            }
            .tab-item-text {
              flex: none;
              height: 20px;
              line-height: 20px;
              font-size: 12px;
              text-align: center;
            }
          }
        }
        .toggle {
          position: absolute;
          right: -1px;
          top: 0;
          // transform: translate(0, -100%);
          border: 1px solid #ccc;
          border-bottom-color: transparent;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          padding: 10px 4px;
          background: #fff;
          cursor: pointer;
          .el-icon-caret-top {
            transition: transform .2s;
          }
        }
        .condition-box {
          display: flex;
          align-items: center;
          color: #333;
          height: 40px;
          overflow: hidden;
          transition: all 0.2s;
          .left {
            flex: auto;
            display: flex;
            align-items: center;
            overflow-x: auto;
            .format-item {
              font-size: 13px;
              padding: 2px 4px;
              padding-top: 0.1px;
              margin-left: 20px;
              color: #666;
              border-radius: 3px;
              cursor: pointer;
              &:hover {
                color: #fff;
                background: $main-color;
              }
            }
            .act-item {
              color: #fff;
              background: $main-color;
            }
          }
          .right {
            flex: none;
            display: flex;
            align-items: center;
            font-size: 13px;
            padding: 0 10px;
            i {
              color: #C0C4CC;
            }
            input {
              outline: none;
              border: none;
              border-bottom: 1px solid #ccc;
              padding-left: 10px;
              width: 120px;
              font-size: 13px;
              color: #919699;
              &::-webkit-input-placeholder {
                color: #DCDFE6;
              }
            }
          }
        }
        .style-box {
          transition: all 0.2s;
          display: flex;
          flex-wrap: wrap;
          padding: 10px;
          max-height: 200px;
          overflow-y: auto;
          .item {
            width: 220px;
            height: 90px;
            margin: 4px;
            border: 1px solid transparent;
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 20px;
            cursor: pointer;
            position: relative;
            .del {
              position: absolute;
              right: 0;
              top: 0;
              transform: translate(50%, -50%);
              z-index: 2;
              display: none;
            }
            .img {
              height: 40px;
              flex: none;
            }
            .button-box {
              flex: auto;
              align-items: center;
              justify-content: center;
              display: none;
            }
            .text-box {
              flex: auto;
              align-items: center;
              justify-content: center;
              display: flex;
              .el-icon-success {
                color: $main-color;
                margin-right: 6px;
              }
            }
            &:hover {
              border-color: $main-color;
            }
            &:hover {
              .del {
                display: unset;
              }
              .button-box {
                display: flex;
              }
              .text-box {
                display: none;
              }
            }
          }
          .act-item {
            background-color: #409eff1f;
          }
        }
      }
      .class-select {
        margin-top: 20px;
      }
      .closed {
        border-color: transparent;
        .toggle {
          border: 1px solid #ccc;
          border-radius: 4px;
          .el-icon-caret-top {
            transform: rotate(180deg);
          }
        }
        .condition-box {
          height: 0;
        }
        .style-box {
          overflow: hidden;
          height: 0;
          padding: 0;
        }
        .style-lib-tabs {
          height: 0;
          overflow: hidden;
        }
      }
      .preview {
        flex: none;
        height: 200px;
        background-color: #F2F6FC;
        margin: 20px 0;
        position: relative;
        padding: 4px;
        overflow: hidden;
        .compare-container {
          width: 100%;
          height: 100%;
          overflow: auto;
          padding: 20px;
        }
        .chapter-preview {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
          pointer-events: none;
        }
        .btn {
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 2;
        }
      }
      .no-preview {
        flex: none;
        height: 200px;
        background-color: #F2F6FC;
        margin: 20px 0;
        position: relative;
        padding: 4px;
        overflow: hidden;
        text-align: center;
        line-height: 200px;
        font-size: 16px;
      }
      .config {
        flex: auto;
        overflow-y: auto;
        position: relative;
        .right-btn {
          position: absolute;
          right: 0;
          top: 0;
          line-height: 40px;
          z-index: 2;
        }
        /deep/ .el-radio__label {
          width: unset;
        }
        /deep/ .el-checkbox__label {
          width: unset;
        }
        /deep/ .el-form-item {
          margin-bottom: 0;
          padding: 10px;
          margin-right: unset;
        }
        .label, /deep/ .el-form-item__label, /deep/ .el-form-item__content {
          font-size: 14px;
          line-height: 17px;
          color: #000;
        }
        /deep/ .el-form-item__label {
          padding-right: 0;
        }
        /deep/ .el-tabs {
          height: 100%;
          .el-tabs__header {
            padding-right: 80px;
          }
          .el-tabs__content {
            overflow-y: auto;
            height: calc(100% - 55px);
          }
        }
        /deep/ .el-form,form{
          padding-bottom: 50px !important;
        }
      }
    }
  }

  .style-list, .style-content {

    height: calc(100vh - 150px);
    border: 1px solid #ccc;

    .header {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      background: #f1f1f1;
      border-bottom: 1px solid #ccc;

      .lib {
        padding: 0 15px;
        cursor: pointer;
        line-height: 1;
        align-self: stretch;
        line-height: 30px;
        background: #fff;

      }
      .close-icon {
        i {
          transform: rotate(180deg);
        }
      }
    }

    .body {
      height: calc(100% - 30px);
      overflow: auto;
    }

  }

  .style-content {
    border: 0px;

    .header {
      border: 1px solid #ccc;
    }
  }

  /deep/ .el-collapse-item {

    .el-collapse-item__header {
      height: 30px;
      padding-left: 5px;
      border-bottom: 1px solid #cecece;
    }

    .el-collapse-item__wrap {
      border-bottom: 0px;
    }

  }
}

</style>
